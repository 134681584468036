<template>
  <v-card
      v-if="details.profile"
      rounded="lg"
      class="mx-auto white--text"
      max-width="374"
      color="light-blue lighten-1"
  >
    <v-img
        alt="photo"
        height="250"
        :src="require('@/assets/images/photo.webp')"
    />
    <v-card-title class="justify-center text-h5">{{ details.profile.name.value }}</v-card-title>
    <v-card-subtitle class="text-center white--text">{{ details.profile.title.value }}</v-card-subtitle>

    <div class="text-center">
      <v-btn text rounded dark :href="resumeLink" :title="$t('label.resume')" target="_blank">
        <v-icon left>
          mdi-download
        </v-icon>
        {{ $t('label.resume') }}
      </v-btn>
    </div>

    <v-card-text class="white--text">
      <v-card-title>{{ $t('label.about_me') }}</v-card-title>
      <v-card-subtitle class="white--text" v-text="details.profile.description[language].value"></v-card-subtitle>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-text>
      <v-list dense color="transparent" dark disabled>
        <v-list-item-group v-model="details">
          <v-list-item v-for="(data, field) in profile" :key="field">
            <v-list-item-icon>
              <v-icon v-text="data.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t(`label.${field}`)"></v-list-item-title>
              <v-list-item-subtitle v-text="data.value"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-text class="white--text">
      <v-list dense color="transparent" dark>
        <v-list-item-group v-model="details">
          <v-list-item v-for="(data, field) in social" :key="field" :href="data.value" :title="field" target="_blank">
            <v-list-item-icon>
              <v-icon v-text="data.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="data.value"/>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

    </v-card-text>
  </v-card>
</template>
<script>

export default {
  name: 'Sidebar',
  data: () => ({
    fields: [
      'birthday',
      'address',
      'email',
      'phone',
    ]
  }),
  computed: {
    details(){
      return this.$store.getters['details/list'] || {}
    },
    profile(){
      return Object.fromEntries(Object.entries(this.details.profile).filter(([index]) => this.fields.includes(index)))
    },
    social(){
      return this.details.social
    },
    resumeLink: function () {
      // let locale = this.$store.getters['lang/currentLocale'];
      // locale = locale === 'pt_BR' ? 'br' : locale;
      return process.env.VUE_APP_BACKEND_URL + 'resume/' + this.language;
      // pdf: process.env.VUE_APP_BACKEND_URL + 'resume/' + locale + '/1',
      // print:
    },
    language(){
      return this.$i18n.locale
    }
  },
  mounted() {
    this.listDetails();
  },
  methods: {
    /**
     * List the details
     * @param params
     * @returns {Promise<boolean>}
     */
    listDetails(params = {}) {
      return this.$store.dispatch('details/list', params);
    }
  }
}
</script>
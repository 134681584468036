<template>
  <v-main class="pt-0">
    <v-container fluid>
      <Header/>
      <v-row>
        <v-col xl="2" lg="2" md="3" sm="4" xs="12" :order="sidebar">
          <Sidebar/>
        </v-col>
        <v-col xl="8"  lg="8" md="6" sm="8" xs="12" :order="main">
          <router-view :selectedItem="selectedItem" @emitToAside="onEmitToAside"/>
        </v-col>
        <v-col xl="2" lg="2" md="3" sm="4" xs="12" :order="aside">
          <router-view name="aside" :emittedToAside="emittedToAside" @selectItem="onSelectItem"/>
        </v-col>
      </v-row>
    </v-container>
    <Footer/>
  </v-main>
</template>
<script>
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

export default {
  name: 'Default',
  data: () => ({
    selectedItem: null,
    emittedToAside: null
  }),
  components: {Footer, Sidebar, Header},
  methods: {
    onSelectItem(item) {
      this.selectedItem = item;
    },
    onEmitToAside(item) {
      this.emittedToAside = item;
    }
  },
  computed: {
    sidebar() {
      if (this.$route.path === '/resume' || this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.thresholds.sm) {
        return 1;
      }
      return 3;
    },
    main() {
      if (this.$route.path === '/resume' || this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.thresholds.sm) {
        return 2;
      }
      return 2;
    },
    aside() {
      if (this.$route.path === '/resume' || this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.thresholds.sm) {
        return 3;
      }
      return 1;
    }
  }
};
</script>
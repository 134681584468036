<template>
  <v-footer padless color="transparent">
    <v-col class="text-center" cols="12">
      © {{ new Date().getFullYear() }} — Made with ❤️ + <v-icon v-text="'mdi-vuejs'" color="#41B883"/> + <v-icon v-text="'mdi-vuetify'" color="#1697F6"/> + <v-icon v-text="'mdi-laravel'" color="#F05340"/>
      by <strong>Marcelo Rodovalho</strong> - v{{version}}
    </v-col>
  </v-footer>
</template>
<script>

export default {
  name: 'Footer',
  computed: {
    version(){
      return this.$store.getters['version/version']
    }
  },
  mounted() {
    this.loadVersion();
  },
  methods: {
    /**
     * Load the version number
     * @param params
     * @returns {Promise<boolean>}
     */
    loadVersion(params = {}) {
      return this.$store.dispatch('version/load', params);
    }
  }
}
</script>
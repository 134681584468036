<template>
  <v-tabs centered icons-and-text background-color="transparent" color="light-blue lighten-1" class="mt-5"
          :fixed-tabs="$vuetify.breakpoint.mobile">
    <v-tab
        v-for="link in links"
        :key="link.meta.title"
        :to="link.path"
    >
      {{ $tc(link.meta.title, 2) }}
      <v-icon x-large>{{ link.meta.icon }}</v-icon>
    </v-tab>
  </v-tabs>
</template>
<script>
import nav from "~/router/routes";
export default {
  name: 'Nav',
  data: () => ({
    links: nav.find(menu => menu.path = '/' && menu.children).children
  })
}
</script>
<style>
.v-slide-group__prev--disabled,
.v-slide-group__next--disabled {
  display: none !important;
}
div.v-tabs-bar .v-tab{
  padding: 0 !important;
}
</style>
<template>
  <div>
    <v-app-bar
        app
        color="transparent"
        flat
        dense
        height="30"
    >
      <v-spacer></v-spacer>
      <v-menu offset-y max-width="60" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              color="green"
              small
              v-bind="attrs"
              v-on="on"
          >
            <country-flag :country='flag' size='small'/>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
              v-for="(item, index) in items"
              :key="index"
              class="pr-0 pl-0"
              @click="changeLocale(item.language)"
          >
            <country-flag :country='item.flag' size='small'/>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-row justify="center" align="center" class="mt-7">
      <v-img
          :src="require('@/assets/images/logo-large.svg')"
          alt="logo"
          transition="scale-transition"
          :max-width="maxWidth"
          class="pl-2 pr-2"
      />
    </v-row>
    <v-row>
      <v-col class="px-0 py-0">
        <Nav/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CountryFlag from 'vue-country-flag';
import Nav from './Nav';
export default {
  name: 'Header',
  components: {CountryFlag, Nav},
  data: () => ({
    items: {
      pt: {flag: 'br', language: 'pt', title: 'English'},
      en: {flag: 'gb', language: 'en', title: 'Português'},
    },
  }),
  computed: {
    language() {
      return this.$i18n.locale
    },
    flag() {
      return this.items[this.language].flag
    },
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm': return this.$vuetify.breakpoint.width
        case 'md':
        case 'lg':
        case 'xl': return this.$vuetify.breakpoint.thresholds.sm
      }
      return null;
    }
  },
  methods: {
    changeLocale(language) {
      this.$i18n.locale = language;
    }
  }
}
</script>